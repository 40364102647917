import { BoardEntityType } from '@features/boards/types/boardEntity';
import { useLiveBoardQuery } from 'api';
import { generateSort } from '../../cargo/utils/generateSort';
import { LIVE_BOARDS_QUERY_KEY } from '../../useLiveBoardGetBoardApi/hook/useLiveBoardGetBoardApi';
import { searchCargoPageQuery } from '../graphql/searchCargoPage';
import { searchOrderPageQuery } from '../graphql/searchOrderPage';

type Args = {
  size?: number;
  externalBoardId?: string;
  sort?: string;
  entityId: string | null;
  entityType: BoardEntityType;
};

const BOARD_ORDER_PAGE = 'order-page';

export const BOARD_CARGO_PAGE = 'cargo-page';

const EntityKey = { [BoardEntityType.CARGO]: BOARD_CARGO_PAGE, [BoardEntityType.ORDER]: BOARD_ORDER_PAGE };

export const useBoardSearchEntityPageApi = ({ size = 20, sort, externalBoardId = '', entityId, entityType }: Args) =>
  useLiveBoardQuery(
    [LIVE_BOARDS_QUERY_KEY, EntityKey[entityType], externalBoardId, entityId, size, sort],
    async (_, gqlClient) => {
      if (!entityId) {
        return undefined;
      }
      if (entityType === BoardEntityType.CARGO) {
        return gqlClient
          .request(searchCargoPageQuery, {
            size,
            sort: generateSort(sort),
            externalBoardId,
            entityId,
          })
          .then(({ searchCargoPage }) => searchCargoPage);
      }
      if (entityType === BoardEntityType.ORDER) {
        return gqlClient
          .request(searchOrderPageQuery, {
            size,
            sort: generateSort(sort),
            externalBoardId,
            entityId,
          })
          .then(({ searchOrderPage }) => searchOrderPage);
      }
      return undefined;
    },
    {
      enabled: !!entityId,
    },
  );
