import { graphql } from '../../../../generated/gql';

export const patchPurchaseOrderMutation = graphql(/* GraphQL */ `
  mutation patchPurchaseOrder(
    $buyerId: ID
    $cargoReadyDate: String
    $customerSupplierId: ID
    $deliveryDate: String
    $destinationLocationId: String
    $forwarderName: String
    $id: ID!
    $orderConfirmedDate: String
    $orderDate: String
    $originLocationId: String
    $poNumber: String
    $version: String
    $status: POStatus
  ) {
    patchPurchaseOrder(
      po: {
        buyerId: $buyerId
        cargoReadyDate: $cargoReadyDate
        customerSupplierId: $customerSupplierId
        deliveryDate: $deliveryDate
        destinationLocationId: $destinationLocationId
        forwarderName: $forwarderName
        id: $id
        orderConfirmedDate: $orderConfirmedDate
        orderDate: $orderDate
        originLocationId: $originLocationId
        poNumber: $poNumber
        version: $version
        status: $status
      }
    ) {
      id
    }
  }
`);
