import { BoardDTO } from '@beacon-devops/beacon-boards-management-model-ts-client';
import { useQueryClient } from '@tanstack/react-query';
import { useLiveBoardQuery } from 'api';
import { BoardFromBoardQuery, boardQuery } from '../graphql/board';

export const LIVE_BOARDS_QUERY_KEY = 'live-boards';

type useLiveBoardGetBoardArgs<T> = {
  externalBoardId?: string | null;
  select: (data: BoardFromBoardQuery) => T;
  enabled?: boolean;
};
export const useLiveBoardGetBoardApi = <T>({ externalBoardId, select, enabled = true }: useLiveBoardGetBoardArgs<T>) =>
  useLiveBoardQuery(
    [LIVE_BOARDS_QUERY_KEY, 'board', externalBoardId],
    async (_, gqlClient) =>
      gqlClient
        .request(boardQuery, {
          externalBoardId,
        })
        .then((data) => {
          return data.board;
        }),
    {
      enabled,
      select,
      retry: false,
    },
  );

export const useCacheUpdateLiveBoard = ({ externalBoardId }: { externalBoardId: string }) => {
  const queryClient = useQueryClient();
  return (data: BoardDTO) => {
    queryClient.setQueryData([LIVE_BOARDS_QUERY_KEY, 'board', externalBoardId], data);
  };
};

export const useInvalidateLiveBoard = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: [LIVE_BOARDS_QUERY_KEY, 'board'] });
  };
};
