import { graphql } from '../../../generated/gql';

export const createShipmentsMutation = graphql(/* GraphQL */ `
  mutation createShipments($shipments: [CreateBasicShipmentInput!]!, $externalBoardId: ID!) {
    createShipments(shipments: $shipments, externalBoardId: $externalBoardId) {
      containers {
        customerId
        externalContainerId
        status
      }
      errors {
        error
        errorEnum
        value
      }
    }
  }
`);
