import React from 'react';
import { SelfPaymentStep } from './steps';
import { ChoosePlan } from './Steps/ChoosePlan/ChoosePlan';
import { PayByCard } from './Steps/PayByCard/PayByCard';
import { UpgradePlan } from './Steps/UpgradePlan/UpgradePlan';

export const SelfPaymentStepList: {
  [key in keyof typeof SelfPaymentStep]: {
    component: React.JSX.Element;
    title: string;
    previousStep?: SelfPaymentStep;
  };
} = {
  [SelfPaymentStep.TRIAL_ENDED]: {
    component: <UpgradePlan trialEnded />,
    title: 'Your free trial has ended!',
  },
  [SelfPaymentStep.UPGRADE_PLAN]: {
    component: <UpgradePlan trialEnded={false} />,
    title: 'Upgrade your plan',
  },
  [SelfPaymentStep.CHOOSE_PLAN]: {
    component: <ChoosePlan />,
    title: 'Choose a plan',
  },
  [SelfPaymentStep.PAY_BY_CARD]: {
    component: <PayByCard />,
    title: 'Book a call to complete checkout',
  },
};
