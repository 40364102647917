import { ColumnConfig, FilterTooltipKeysAir } from '../../types/tooltip';

// reverse map for field name -> UI label to show in column
export const AirFilterColumnTitles = {
  cargo_search_term: 'Search',
  status: 'Current status',
  'labels.id': 'Labels',
  'slimPurchaseOrders.customerSupplierId': 'Supplier',
  'slimPurchaseOrders.buyerId': 'Buyer',
  forwarderName: 'Forwarder',
  placeholder: 'Show only orders without shipments',
  'slimPurchaseOrders.originLocation.id': 'Order origin',
  'slimPurchaseOrders.destinationLocation.id': 'Order destination',
  filter_cargo_arrival_departure_timeframe: 'Timeframe',
  filter_cargo_arrival_departure_relative_timeframe: 'Timeframe',
  'carrier.id': 'Airline',
  'transportSummary.primaryLoadLocation.id': 'Departure Airport',
  'transportSummary.primaryDischargeLocation.id': 'Arrival Airport',
  'transportSummary.warehouseLocation.id': 'Warehouse',
  'transportSummary.currentJourneyNumber': 'Flight number',
  'transportSummary.legs': 'Routing',
  toggles: 'Other filters',
};

// columns and sections configuration derived from requirements here
// https://www.figma.com/file/Qz4X7sS77hNkm6PtPCjOTZ/Live-Board?type=design&node-id=1202-128157&mode=dev
export const AirFilterTooltipColumns: ColumnConfig[] = [
  [FilterTooltipKeysAir.Search],
  [FilterTooltipKeysAir.CurrentStatus],
  [
    FilterTooltipKeysAir.Labels,
    FilterTooltipKeysAir.Supplier,
    FilterTooltipKeysAir.Buyer,
    FilterTooltipKeysAir.Forwarder,
  ],
  [FilterTooltipKeysAir.Timeframe, FilterTooltipKeysAir.RollingTimeframe],
  [
    FilterTooltipKeysAir.OrderOrigin,
    FilterTooltipKeysAir.DepartureAirport,
    FilterTooltipKeysAir.ArrivalAirport,
    FilterTooltipKeysAir.Warehouse,
    FilterTooltipKeysAir.OrderDestination,
    FilterTooltipKeysAir.Airline,
    FilterTooltipKeysAir.FlightNumber,
    FilterTooltipKeysAir.Placeholder,
  ],
  [FilterTooltipKeysAir.Toggles],
];
