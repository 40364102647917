import { PropsWithChildren } from 'react';
import { Box, Icon, Text, theme } from '@beacon-devops/components';
import { FlexContainer } from '@components/common';
import { PLAN_FEATURES } from '../../../../constants';
import { Plan } from '../../../../types';

type PlanListProps = {
  planItemList: string[];
};

type PlanFeatureProps = {
  selectedPlan: Plan;
};

function PlanItem({ children }: PropsWithChildren) {
  return (
    <FlexContainer flexDirection="row" gap="20px" ml={4}>
      <Icon circleColor="white" color={theme.colors.success} name="controls-check" size="medium" />
      <Text fontFamily="regular" fontSize={2} lineHeight="20px">
        {children}
      </Text>
    </FlexContainer>
  );
}

function PlanItemsList({ planItemList }: PlanListProps) {
  return (
    <>
      {planItemList.map((feature) => (
        <PlanItem key={feature}>{feature}</PlanItem>
      ))}
    </>
  );
}

export function PlanFeatures({ selectedPlan }: PlanFeatureProps) {
  const getHeading = (plan: Plan) => {
    switch (plan) {
      case 'starter':
        return 'What’s included';
      case 'business':
        return 'Everything included in Starter, plus...';
      case 'pro':
        return 'Everything included in Business, plus... ';
      default:
        return '';
    }
  };

  return (
    PLAN_FEATURES[selectedPlan] && (
      <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
        <FlexContainer flexDirection="column" gap="15px" maxWidth="488px">
          <Box display="flex" flexDirection="row" alignItems="left">
            <Text fontFamily="medium" fontSize={2} lineHeight="21px">
              {getHeading(selectedPlan)}
            </Text>
          </Box>
          <PlanItemsList planItemList={PLAN_FEATURES[selectedPlan]} />
        </FlexContainer>
      </Box>
    )
  );
}
