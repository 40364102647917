import { Mode } from '@services/ShipmentDataGatewayService';
import { UpgradePlanCallToAction } from './components/upgradePlan/UpgradePlanCallToAction';

export const TRIAL_WARNING_THRESHOLD = 7;
export const DAYS_REMAINING_WARNING = 4;
export const TRIAL_DISMISSED_KEY = 'beacon-trial-banner-dismissed';

export const DefaultCreditUsage = {
  [Mode.Air]: { left: 0, used: 0, total: 0 },
  [Mode.Ocean]: { left: 0, used: 0, total: 0 },
  [Mode.Road]: { left: 0, used: 0, total: 0 },
};

export const UpgradeMessages = {
  historicalShipments: () => ({
    title: "You look like someone who's searching for more",
    text: 'Upgrade your plan to see all your orders and historical shipments.',
    cta: <UpgradePlanCallToAction>Get in touch to learn more</UpgradePlanCallToAction>,
  }),
  boardsLimit: (boardCount: number, limit: number) => ({
    title: `You have reached your Live Boards limit (${boardCount}/${limit})`,
    text: 'To create a Live Board, delete existing ones or',
    cta: <UpgradePlanCallToAction>upgrade your plan</UpgradePlanCallToAction>,
  }),
};
