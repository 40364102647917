import { graphql } from '../../../../generated/gql';
import { SearchPosWithMinimalDataQuery } from '../../../../generated/graphql';

export const searchPosWithMinimalData = graphql(/* GraphQL */ `
  query searchPosWithMinimalData(
    $page: Int! = 0
    $sort: [SearchSortCriteria!]
    $size: Int! = 1000
    $externalBoardId: String
  ) {
    searchPurchaseOrdersByExternalBoardId(page: $page, size: $size, sort: $sort, externalBoardId: $externalBoardId) {
      pos {
        id
        customerSupplierId
        poNumber
        supplierName
      }
    }
  }
`);

export type PosWithMinimalData = NonNullable<
  SearchPosWithMinimalDataQuery['searchPurchaseOrdersByExternalBoardId']
>['pos'];
