import { DistanceUnits, EmailVerifiedStatus } from '@beacon-devops/customer-service-client';
import { FrontendUser } from 'services/UserService/useCurrentUser';

export const userMockEmailVerificationPending: FrontendUser = {
  userId: '3243aaaa-645b-47bc-b5db-f79def876a6d',
  contactDetails: {
    firstName: 'Jane',
    lastName: 'Doe',
    phoneNumber: '4470041100',
  },
  customerId: 'd709cbc6-8d72-4f14-ba48-7faa11586c0c',
  email: 'jane-doe@beacon.com',
  isActive: true,
  emailVerified: EmailVerifiedStatus.Pending,
  version: 'v1',
  isTest: false,
  created: '',
  fullName: 'Jane Doe',
  beaconUserId: 'bcn1.c.user.3243aaaa-645b-47bc-b5db-f79def876a6d',
  isBeaconEmployee: true,
  preferences: {
    tracking: {
      distanceUnits: DistanceUnits.Mi,
    },
  },
};

export const yetToBeOnboardedUser: FrontendUser = {
  ...userMockEmailVerificationPending,
  contactDetails: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  customerId: 'd709cbc6-8d72-4f14-ba48-7faa11586c0c',
  email: 'yet-onboarded@beacon.com',
  emailVerified: EmailVerifiedStatus.Verified,
  fullName: '',
};
