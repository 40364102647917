import { useCurrentUserIfPresentFromContextAsync } from '@features/auth/user/currentUser/context';
import { IntercomUpdatePayload } from '../types';

export function useIntercomInteraction() {
  const { currentUser, currentUserCustomer } = useCurrentUserIfPresentFromContextAsync();

  const hasIntercom = typeof Intercom !== 'undefined';

  return {
    startPostOnboardingChecklist: () => hasIntercom && Intercom('startChecklist', 323907),

    showNewMessage: () => hasIntercom && Intercom('showNewMessage'),

    updateUserDetails(payload: IntercomUpdatePayload) {
      if (hasIntercom) {
        Intercom('update', payload);
      }
    },

    /*
     * This functions is to be called when a user adds any shipment to the system.
     * We want to flag on a company (customer) level that that happened.
     * So that a new onboarding checklist can be triggered.
     */
    customerHasUploadedShipments() {
      if (hasIntercom) {
        Intercom('update', {
          // Recommended to send `email` and/or `user_id` by the docs https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
          email: currentUser?.email,
          user_id: currentUser?.beaconUserId,
          company: {
            id: currentUserCustomer?.customerId,
            name: currentUserCustomer?.fullCompanyName,
            has_uploaded_shipments: true,
          },
        });
      }
    },

    shutdown() {
      if (hasIntercom) {
        Intercom('shutdown');
      }
    },
  };
}
