import { graphql } from '../../../../../generated/gql';

export const LocationFragment = graphql(/* GraphQL */ `
  fragment TransportSummaryLocation on LocationWithMilestones {
    location {
      id
      name
      facility {
        iataCode
      }
      unLocation {
        code
        countryName
      }
      address {
        address1
        city
        postcode
        country
      }
      geoLocation {
        lat
        lon
      }
    }
    milestoneViews {
      summary {
        type
        label
        dateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
        }
        hasBeenAchieved
      }
      full {
        type
        label
        order
        isStandalonePreviousDateMilestone
        hasBeenAchieved
        dateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
        otherDateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
      }
    }
  }
`);

export const searchCargosAndPOsQuery = graphql(/* GraphQL */ `
  query searchCargosAndPOs(
    $page: Int! = 0
    $size: Int! = 20
    $criteria: [SearchCriteria!]
    $macros: [GqlMacro!]
    $sort: [SearchSortCriteria!]
  ) {
    searchCargosAndPOs(page: $page, size: $size, criteria: $criteria, macros: $macros, sort: $sort) {
      page
      pageSize
      totalPages
      totalRecords
      totalOceanRecords
      totalAirRecords
      totalRoadRecords
      cargos {
        forwarderName
        id
        errors {
          type
        }
        customFields {
          key
          value
        }
        carrierShipmentId
        status
        placeholder
        modeOfTracking
        freshnessTimestamp
        mode
        numberOfPackages
        shipmentReferences {
          id
          type
          value
          purchaseOrderId
        }
        container {
          containerNumber
        }
        createdAt
        createdByName
        labels {
          id
          name
        }
        carrier {
          displayName
          nmftaCode
          iataCode
        }
        slimPurchaseOrders {
          id
          poNumber
          customerSupplierId
          supplierName
          buyerName
          buyerId
          cargoReadyDate
          createdOn
        }
        isWatched
        transportSummary {
          legs
          currentLeg
          haulagePairExists
          transportDistances {
            total {
              value
              unit
            }
            remaining {
              value
              unit
            }
          }
          currentJourneyNumber
          currentMode
          currentVehicle {
            displayName
            identification {
              licensePlate
            }
          }
          origin {
            ...TransportSummaryLocation
          }
          primaryDischarge {
            ...TransportSummaryLocation
          }
          destination {
            ...TransportSummaryLocation
          }
        }
      }
    }
  }
`);
