import { graphql } from '../../../generated/gql';

export const getVehicleEventsByImoNumberQuery = graphql(/* GraphQL */ `
  query GetVehicleEventsByImoNumber($imoNumbers: [String!]!) {
    vehicleEventsByImoNumber(imoNumbers: $imoNumbers) {
      id
      type
      subType
      source {
        sourceType
        identifier
        displayName
      }
      eventDateTime {
        date
        timestamp
      }
      vehicle {
        id
        identification {
          imoNumber
          mmsiNumber
          tailNumber
          licensePlate
        }
      }
      trackingInfo {
        geoLocation {
          lat
          lon
        }
        headingDegrees
        speedKph
      }
    }
  }
`);
