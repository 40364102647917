import { createContext, useContext } from 'react';

interface ModalContextProps<T, S> {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  currentStep: S;
  modalData: T;
  setPartialModalData: (data: Partial<T>) => void;
  changeStep: (nextViewKey: S, data?: Partial<T>) => void;
}

const defaultContextValue: ModalContextProps<unknown, string> = {
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
  currentStep: '',
  modalData: {},
  changeStep: () => {},
  setPartialModalData: () => {},
};

export const ModalContext = createContext<ModalContextProps<unknown, unknown>>(defaultContextValue);

export const useModalContext = <T, S>(): ModalContextProps<T, S> => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context as ModalContextProps<T, S>;
};
