import { graphql } from '../../../../generated/gql';

export const createSupplierMutation = graphql(/* GraphQL */ `
  mutation createSupplier($supplierName: String!, $relationshipToCustomer: RelationshipToCustomer) {
    createSupplier(supplierName: $supplierName, relationshipToCustomer: $relationshipToCustomer) {
      legalName
      supplierId
    }
  }
`);
