export const PLAN_FEATURES = {
  starter: [
    '3 Live boards',
    'Single platform for ocean, air and road tracking with all milestones, across 160+ ocean, 150+ airlines and 158,000 road carriers',
    'Live chat with your teams, suppliers, warehouses, forwarders, and customers',
  ],
  business: [
    '15 Live boards',
    'Order and SKU level tracking',
    'Automated reporting for the last 12 months',
    'Link documents to individual shipments',
    'Flex Beacon to your needs with custom fields',
    'Improve inbound visibility with custom warehouse ETA',
    'Dedicated Customer Success Manager',
  ],
  pro: [
    'Unlimited Live Boards',
    'Exceptions dashboard to manage disruptions at scale',
    'Speed up communications with centralized inbox',
    'Insights & analytics across all your historical shipments',
    'Scope 3 emissions reporting across all transport modes, based on one certified methodology',
    'All add-ons and premium support',
  ],
};
