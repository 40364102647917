export enum BoardChipVariant {
  Standard = 'standard',
  Inverted = 'inverted',
}

export interface BoardChipConfig {
  label: string;
  variant?: BoardChipVariant;
}

export type BoardChipConfigRepr = Record<string, BoardChipConfig[]>;

export interface FilterSection {
  title: string;
  chips: BoardChipConfig[];
}

export type FilterTooltipColumn = FilterSection[];

export type FilterDataObj = { id: string; value: string };

export type FilterData = {
  field: string;
  data: FilterDataObj[];
};

export enum FilterTooltipKeysAir {
  Search = 'cargo_search_term',
  CurrentStatus = 'status',
  Labels = 'labels.id',
  Supplier = 'slimPurchaseOrders.customerSupplierId',
  Buyer = 'slimPurchaseOrders.buyerId',
  Forwarder = 'forwarderName',
  Placeholder = 'Show only orders without shipments',
  OrderOrigin = 'slimPurchaseOrders.originLocation.id',
  OrderDestination = 'slimPurchaseOrders.destinationLocation.id',
  Timeframe = 'filter_cargo_arrival_departure_timeframe',
  RollingTimeframe = 'filter_cargo_arrival_departure_relative_timeframe',
  Airline = 'carrier.id',
  DepartureAirport = 'transportSummary.primaryLoadLocation.id',
  ArrivalAirport = 'transportSummary.primaryDischargeLocation.id',
  Warehouse = 'transportSummary.warehouseLocation.id',
  FlightNumber = 'transportSummary.currentJourneyNumber',
  Routing = 'transportSummary.legs',
  Toggles = 'toggles',
}

export enum FilterTooltipKeysOcean {
  Search = 'cargo_search_term',
  CurrentStatus = 'status',
  Labels = 'labels.id',
  Supplier = 'slimPurchaseOrders.customerSupplierId',
  Buyers = 'slimPurchaseOrders.buyerId',
  Forwarder = 'forwarderName',
  Placeholder = 'placeholder',
  Timeframe = 'filter_cargo_arrival_departure_timeframe',
  RollingTimeframe = 'filter_cargo_arrival_departure_relative_timeframe',
  Carrier = 'carrier.id',
  Vessel = 'transportSummary.currentVehicle.displayName',
  OrderOrigin = 'slimPurchaseOrders.originLocation.id',
  OrderDestination = 'slimPurchaseOrders.destinationLocation.id',
  Origin = 'transportSummary.originLocation.id',
  PortOfLoading = 'transportSummary.primaryLoadLocation.id',
  TransshipmentPort = 'transportSummary.transshipmentLocations.id',
  PortOfDischarge = 'transportSummary.primaryDischargeLocation.id',
  Destination = 'transportSummary.destinationLocation.id',
  Warehouse = 'transportSummary.warehouseLocation.id',
  Routing = 'transportSummary.legs',
  Toggles = 'toggles',
}

export enum FilterTooltipKeysRoad {
  Search = 'cargo_search_term',
  CurrentStatus = 'status',
  Labels = 'labels.id',
  Supplier = 'slimPurchaseOrders.customerSupplierId',
  Timeframe = 'filter_cargo_arrival_departure_timeframe',
  RollingTimeframe = 'filter_cargo_arrival_departure_relative_timeframe',
  Haulier = 'carrier.id',
  PickUpLocation = 'transportSummary.primaryLoadLocation.id',
  DropOffLocation = 'transportSummary.primaryDischargeLocation.id',
  Toggles = 'toggles',
}

export type ColumnConfig = (FilterTooltipKeysOcean | FilterTooltipKeysAir | FilterTooltipKeysRoad)[];
