import { Mode } from '@services/LiveBoardsService';

export enum SearchMode {
  All = 'ALL',
  Air = 'AIR',
  Ocean = 'OCEAN',
  Road = 'ROAD',
}

export const getSearchModeFromMode = (mode: Mode) => {
  if (mode === Mode.Ocean) {
    return SearchMode.Ocean;
  }
  if (mode === Mode.Road) {
    return SearchMode.Road;
  }
  if (mode === Mode.Air) {
    return SearchMode.Air;
  }
  return SearchMode.All;
};

export const getModeFromSearchMode = (mode: SearchMode = SearchMode.Ocean) => {
  if (mode === SearchMode.Ocean) {
    return Mode.Ocean;
  }
  if (mode === SearchMode.Road) {
    return Mode.Road;
  }
  if (mode === SearchMode.Air) {
    return Mode.Air;
  }

  return undefined;
};
