import { graphql } from '../../../../generated/gql';

export const generatePreSignedUrlForDocumentUploadQuery = graphql(/* GraphQL */ `
  query generatePreSignedUrlForDocumentUpload(
    $documentName: String! = ""
    $sizeBytes: Int! = 0
    $externalBoardId: String
  ) {
    generatePreSignedUrlForDocumentUpload(
      documentName: $documentName
      sizeBytes: $sizeBytes
      externalBoardId: $externalBoardId
    ) {
      key
      url
    }
  }
`);
