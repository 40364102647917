import { FC, PropsWithChildren } from 'react';
import { Box, HR } from '@beacon-devops/components';
import { useCurrentUserIfPresentFromContextAsync } from '@features/auth/user/currentUser/context';
import { TrialDayCountdownAlert } from '@features/subscription';
import { TrackPageViews } from 'app/components/TrackPageViews';
import { MAX_SCREEN_WIDTH } from 'app/theme/constants';
import PageHeader, { PageHeaderProps } from '../PageHeader';

export interface AppPageLayoutProps extends PageHeaderProps {
  hideAppHeader?: boolean;
  hidePageHeader?: boolean;
  hasSeparator?: boolean;
  contentFullWidth?: boolean;
  removeBottomPadding?: boolean;
}

export const AppPageLayout: FC<PropsWithChildren<AppPageLayoutProps>> = ({
  children,
  hasSeparator,
  hideAppHeader,
  hidePageHeader,
  contentFullWidth,
  removeBottomPadding = false,
  ...props
}) => {
  const { currentUserCustomer } = useCurrentUserIfPresentFromContextAsync();

  return (
    <TrackPageViews>
      <Box
        bg="secondaryShades.6"
        pt={11}
        pb={removeBottomPadding ? 0 : '8rem'}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Box p="0 1.5rem" width="100%" maxWidth={MAX_SCREEN_WIDTH} margin="0 auto">
          {currentUserCustomer && currentUserCustomer.hasRemainingTrialDays && (
            <TrialDayCountdownAlert daysRemaining={currentUserCustomer.freeTrialDaysRemaining!} />
          )}

          {!hidePageHeader && <PageHeader pb={hasSeparator ? 0 : '24px'} {...props} />}

          {hasSeparator && <HR width={1} border="thin" borderColor="secondaryShades.4" my={5} />}
        </Box>
        <Box
          width="100%"
          {...(contentFullWidth ? {} : { maxWidth: MAX_SCREEN_WIDTH, padding: '0 1.5rem' })}
          margin="0 auto"
        >
          {children}
        </Box>
      </Box>
    </TrackPageViews>
  );
};
