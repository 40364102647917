import { useContext } from 'react';
import { FrontendCustomer } from '@services/CompanyService/useOwnCompany';
import { FrontendUser } from 'services/UserService/useCurrentUser';
import { CurrentUserContext } from './CurrentUserContext';
import { UserClaims } from '../auth/claims/userClaims';
import { UserPermissions } from '../auth/permissions/userPermissions';

export interface CurrentUserAlwaysPresentContextType {
  currentUser: FrontendUser;
  permissions: UserPermissions;
  customerPermissions?: UserPermissions;
  limits: UserClaims['limits'];
  boardAccess: UserClaims['boardAccess'];
  currentUserCustomer: NonNullable<FrontendCustomer>;
}

export function useCurrentUserFromContext(): CurrentUserAlwaysPresentContextType {
  const value = useContext(CurrentUserContext);

  if (!value) {
    throw new Error('Context value is undefined');
  }

  if (!value.currentUser) {
    throw new Error('currentUser not present in CurrentUserContext');
  }

  if (!value.permissions) {
    throw new Error('permissions not present in CurrentUserContext');
  }

  if (!value.currentUserCustomer) {
    throw new Error('currentUserCustomer not present in CurrentUserContext');
  }

  const contextValueCurrentUserAlwaysPresent: CurrentUserAlwaysPresentContextType = {
    currentUser: value.currentUser,
    permissions: value.permissions,
    customerPermissions: value.customerPermissions,
    currentUserCustomer: value.currentUserCustomer,
    limits: value.limits,
    boardAccess: value.boardAccess,
  };

  return contextValueCurrentUserAlwaysPresent;
}
