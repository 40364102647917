import { featureAnalyticsBookCall } from './analytics';

type SelfPaymentModalStep =
  | 'trial-ended-book-a-call'
  | 'upgrade-plan_book-a-call'
  | 'choose-plan_book-a-call'
  | 'complete-checkout_book-a-call';

export function openDiscoveryCall() {
  window.open('https://www.beacon.com/select-plan', 'blank');
}

export function bookCall(step: SelfPaymentModalStep, additionalDetail?: string) {
  featureAnalyticsBookCall(step, additionalDetail);
  openDiscoveryCall();
}
