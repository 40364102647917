import { TextButton, TextButtonProps } from '@beacon-devops/components';
import { useIntercomInteraction } from 'app/thirdPartyTools/intercom';

export function UpgradePlanCallToAction(props: TextButtonProps) {
  const { showNewMessage } = useIntercomInteraction();
  return (
    <TextButton color="infoShades.2" {...props} onClick={showNewMessage}>
      {props.children}
    </TextButton>
  );
}
