import { useState, ReactNode, useMemo, useCallback } from 'react';
import { ModalContext } from './ModalContext';

interface ModalProviderProps<T, S> {
  children: ReactNode;
  initialStep: S;
  initialData: T;
}

export const ModalProvider = <T, S>({ children, initialStep, initialData }: ModalProviderProps<T, S>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(initialStep);

  const [modalData, setModalData] = useState<T>(initialData);

  const changeStep = (newViewKey: S, data?: Partial<T>) => {
    if (data) {
      setModalData((prevData) => ({ ...prevData, ...data }));
    }

    if (newViewKey) {
      setCurrentStep(newViewKey);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentStep(initialStep);
    setModalData(initialData);
    setIsModalOpen(false);
  };

  const setPartialModalData = useCallback((data: Partial<T>) => {
    setModalData((prevData) => ({ ...prevData, ...data }));
  }, []);

  const contextState = useMemo(
    () => ({
      isModalOpen,
      openModal,
      closeModal,
      currentStep,
      modalData,
      changeStep,
      setPartialModalData,
    }),
    [isModalOpen, openModal, closeModal, currentStep, modalData, changeStep, setPartialModalData],
  );

  return <ModalContext.Provider value={contextState}>{children}</ModalContext.Provider>;
};
