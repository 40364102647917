import { SearchMode } from '@beacon-types/cargo/mode';
import { TrackingPageContextType } from '@components/tracking/types';

export const CargoSearchCriteriaFields: Record<string, string> = {
  mode: 'mode',
  filters: 'status',
  watchlistFilter: 'isWatched.userId',
  demurrageFilter: 'demurrageInfo.daysOnQuayPoD',
  demurrageValue: 'demurrageInfo.daysOnQuayPoD',
  daysOffQuayFilter: 'demurrageInfo.daysOffQuayDest',
  daysOffQuayValue: 'demurrageInfo.daysOffQuayDest',
  isDaysAtTsPortOn: 'demurrageInfo.daysAtTsPort',
  daysAtTsPortValue: 'demurrageInfo.daysAtTsPort',
  labels: 'labels.id',
  origin: 'transportSummary.originLocation.id',
  portOfLoading: 'transportSummary.primaryLoadLocation.id',
  transhipmentPorts: 'transportSummary.transshipmentLocations.id',
  portOfDischarge: 'transportSummary.primaryDischargeLocation.id',
  destination: 'transportSummary.destinationLocation.id',
  warehouses: 'transportSummary.warehouseLocation.id',
  carriers: 'carrier.id',
  suppliers: 'slimPurchaseOrders.customerSupplierId',
  buyers: 'slimPurchaseOrders.buyerId',
  orderOrigin: 'slimPurchaseOrders.originLocation.id',
  orderDestination: 'slimPurchaseOrders.destinationLocation.id',
  placeholder: 'placeholder', // whether the cargo is a placeholder "dummy" cargo
  modeOfTracking: 'modeOfTracking',
  forwarders: 'forwarderName',
  vessels: 'transportSummary.currentVehicle.displayName',
  poIds: 'slimPurchaseOrders.id',
  flightNumber: 'transportSummary.currentJourneyNumber',
  routingDirect: 'transportSummary.transshipmentLocations.id',
  routingIndirect: 'transportSummary.transshipmentLocations.id',
} as const;

export const MacrosKeys = {
  arrivalDeparturetimeframe: 'filter_cargo_arrival_departure_timeframe',
  arrivalDepartureRelativeTimeframe: 'filter_cargo_arrival_departure_relative_timeframe',
  cargoReadyDateTimeframe: 'filter_cargo_ready_date_timeframe',
  cargoReadyDateRelativeTimeframe: 'filter_cargo_ready_date_relative_timeframe',
  cargoSearchTerm: 'cargo_search_term',
  etaChangesTimeframe: 'eta_changes_time_frame',
  liveShipments: 'live_shipments_filter',
  manualBoardBuilderSelection: 'manual_board_builder_selection',
} as const;

export type SearchMacrosFields = Partial<
  Pick<
    TrackingPageContextType,
    'searchKeyword' | 'milestone' | 'relativeDate' | 'startDate' | 'endDate' | 'etaChangedFilter' | 'cargoReadyDate'
  >
> & { liveShipments?: boolean; selectionList?: string[] };

export type SearchCriteriaFields = Partial<
  Pick<
    TrackingPageContextType,
    | 'labels'
    | 'filters'
    | 'notStatuses'
    | 'demurrageFilter'
    | 'demurrageValue'
    | 'watchlistFilter'
    | 'origin'
    | 'portOfLoading'
    | 'transhipmentPorts'
    | 'portOfDischarge'
    | 'destination'
    | 'warehouses'
    | 'carriers'
    | 'vessels'
    | 'suppliers'
    | 'buyers'
    | 'orderOrigin'
    | 'orderDestination'
    | 'placeholderFilter'
    | 'forwarders'
    | 'isDaysAtTsPortOn'
    | 'daysAtTsPort'
    | 'routingDirect'
    | 'routingIndirect'
    | 'flightNumber'
    | 'daysOffQuayFilter'
    | 'daysOffQuayValue'
  > & { mode?: `${SearchMode}`; cargoIds?: string[]; poIds?: string[]; canViewOCH?: boolean }
>;

export const BolProcessingStatusText = 'Awaiting container assignment';
