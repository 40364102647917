import { Box, Button, Text } from '@beacon-devops/components';
import { FlexContainer } from '@components/common';
import { bookCall } from '../../../../utils';

export function PayByCard() {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <FlexContainer flexDirection="column">
        <Text fontFamily="regular" fontSize={2}>
          Our online pay by card option is currently not available. Please book
          <br />a call with one of our experts to finish checking out.
        </Text>

        <FlexContainer justifyContent="center" mt={6}>
          <Button
            color="primary"
            onClick={() => {
              bookCall('complete-checkout_book-a-call');
            }}
            width="150px"
          >
            Book a call
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Box>
  );
}
