import { sendEvent } from 'beaconAnalytics';

const featureName = 'self-payment';

export function featureAnalyticsBookCall(step: string, additionalDetail?: string) {
  sendEvent({
    featureName,
    eventName: step,
    data: {
      additionalDetail,
    },
  });
}

export function featureAnalyticsNumberOfShipments(nrOfShipments: string) {
  sendEvent({
    featureName,
    eventName: `proceed-to-checkout`,
    data: {
      nrOfShipments,
    },
  });
}

export function featureAnalyticsSelectPlan(plan?: string) {
  sendEvent({
    featureName,
    eventName: 'pay-by-card',
    data: {
      plan,
    },
  });
}

export function featureAnalyticsBoardLockedBookACall() {
  sendEvent({
    featureName,
    eventName: 'board-locked-book-a-call',
  });
}

export function featureAnalyticsUpgradePlan(daysRemaining?: number) {
  sendEvent({
    featureName,
    eventName: 'user-initiated-upgrade-plan',
    data: {
      daysRemaining,
    },
  });
}

export function featureAnalyticsCloseModal(currentStep: string) {
  sendEvent({
    featureName,
    eventName: 'close-modal',
    data: {
      currentStep,
    },
  });
}
