import { useState } from 'react';
import { Box, Button, Dropdown, Text } from '@beacon-devops/components';
import { FlexContainer } from '@components/common';
import { PlanFeatures } from './PlanFeatures';
import { featureAnalyticsSelectPlan } from '../../../../analytics';
import { Plan } from '../../../../types';
import { bookCall } from '../../../../utils';
import { SelfPaymentStep } from '../../steps';
import { useSelfPaymentModalContext } from '../../useSelfPaymentModalContext';

export function ChoosePlan() {
  const { changeStep } = useSelfPaymentModalContext();

  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  const paymentStep = () => {
    featureAnalyticsSelectPlan(selectedPlan);
    changeStep(SelfPaymentStep.PAY_BY_CARD);
  };

  const handleOnChange = (value: Plan) => {
    setSelectedPlan(value);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <FlexContainer flexDirection="column" gap="20px">
        <Text fontFamily="regular" fontSize={2}>
          Select a plan that’s right for you or book a call with one of our experts.
        </Text>

        <Dropdown
          key="plan-dropdown"
          mt={1}
          placeholder="Select your plan"
          options={[
            { label: 'Starter plan ($199/month)', value: 'starter' },
            { label: 'Business plan ($499/month)', value: 'business' },
            { label: 'Pro plan (contact us)', value: 'pro' },
          ]}
          onChange={handleOnChange}
          value={selectedPlan}
          listMaxHeight="300rem"
          withBorder
          shouldClearOnChange
          mb="12px"
          height="45px"
          renderOptionsInsidePortal
        />

        {selectedPlan && <PlanFeatures selectedPlan={selectedPlan} />}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent={selectedPlan === 'pro' ? 'center' : 'space-between'}
          alignItems="center"
        >
          <Button
            variant="outlined"
            color="info"
            width="215px"
            onClick={() => {
              bookCall('choose-plan_book-a-call', selectedPlan);
            }}
            type="button"
          >
            Book a call
          </Button>
          {selectedPlan !== 'pro' && (
            <>
              <Text fontSize={2} color="secondaryShades.3">
                or
              </Text>
              <Button color="primary" width="215px" onClick={paymentStep} data-testid="pay-by-card-button">
                Pay by card
              </Button>
            </>
          )}
        </Box>
      </FlexContainer>
    </Box>
  );
}
