import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { isProd } from '@utils';
import { sentryConfig } from 'app';
import { App } from 'app/components/App';

if (isProd) {
  Sentry.init(sentryConfig);
  Hotjar.init(parseInt(VITE_HOTJAR_APP_ID), 6);
}

const mountNode = document.getElementById('app');

if (mountNode) {
  createRoot(mountNode).render(<App />);
} else {
  throw new Error('Fatal: Could not find mount node for beacon application.');
}
