import { createContext } from 'react';
import { FrontendCustomer } from '@services/CompanyService/useOwnCompany';
import { FrontendUser } from 'services/UserService/useCurrentUser';
import { UserClaims } from '../auth/claims/userClaims';
import { UserPermissions } from '../auth/permissions/userPermissions';

export interface CurrentUserContextType {
  currentUser?: FrontendUser;
  permissions?: UserPermissions;
  /**
   * Customer Permissions are the permissions that are specific to the customer and should only be used as fallback
   *  permissions when the user does not the permissions (e.g.: Board user)
   */
  customerPermissions?: UserPermissions;
  limits?: UserClaims['limits'];
  boardAccess?: UserClaims['boardAccess'];
  currentUserCustomer?: FrontendCustomer;
}

export const CurrentUserContext = createContext<CurrentUserContextType>({
  currentUser: undefined,
  permissions: undefined,
  customerPermissions: undefined,
  currentUserCustomer: undefined,
  limits: undefined,
  boardAccess: undefined,
});
