import { graphql } from '../../../../generated/gql';
import { SlimPurchaseOrderListForFreeTrackingCustomerQuery } from '../../../../generated/graphql';

export const slimPurchaseOrderList = graphql(/* GraphQL */ `
  query slimPurchaseOrderListForFreeTrackingCustomer($externalBoardId: String!) {
    slimPurchaseOrderListForFreeTrackingCustomer(externalBoardId: $externalBoardId) {
      id
      poNumber
      supplierName
      customerSupplierId
    }
  }
`);

export type SlimPurchaseOrderListFromQuery =
  SlimPurchaseOrderListForFreeTrackingCustomerQuery['slimPurchaseOrderListForFreeTrackingCustomer'];
