import Hotjar from '@hotjar/browser';
import { apiObject } from 'rudder-sdk-js';
import { rudderanalytics } from '@services/clickstream';
import { analytics } from './analytics';

interface SendEventProps {
  /**
   * The only required props are eventName and featureName
   *
   * Motivation -
   * So far we have been writing event names without much thought, because anything will work in BigQuery.
   * But as these events are also being used in Hotjar to build dashboards, we now have a way to name them better.
   * Hotjar allows us to create dashboards / trends / funnels etc based on event names, hence event name should correspond to
   * some user behaviour, like clicking a button, submitting a form, filling an input field etc.
   *
   * 1. Event name should in almost all cases should cause the user interface to change.
   * 2. Keep the name tied to the feature the event is related to, if the event is not related to a feature you might need to think again
   * 3. Keep the name concise and meaningful, very long names are hard to read and understand as well as hard to work with in tools like Hotjar or GA
   * 4. Do not include URL or page in event name, they are derivable from page URL
   * 5. Feature name can sometimes be same as a component name, for example "cargo-card" or "app-header", feel free to use them as is
   * 6. Any data you want to pass that is not UI related should be added to the data object and not in event name
   */

  eventName: string;
  featureName: string;
  data?: apiObject;
  alsoSendTo?: {
    intercom?:
      | boolean
      | {
          name?: string;
          data?: apiObject;
        };
  };
}

export const sendEvent = ({ eventName, featureName, data, alsoSendTo }: SendEventProps): void => {
  const name = `${featureName}_${eventName}`;
  if (alsoSendTo?.intercom && typeof Intercom !== 'undefined') {
    Intercom(
      'trackEvent',
      typeof alsoSendTo?.intercom === 'object' ? alsoSendTo?.intercom?.name : name,
      typeof alsoSendTo?.intercom === 'object' ? alsoSendTo?.intercom?.data : undefined,
    );
  }

  if (Hotjar.isReady()) {
    Hotjar.event(name);
  }

  analytics.track(name, data);
  rudderanalytics.track(name, data);
};
