import { graphql } from '../../generated/gql';

export const POLocationFragment = graphql(/* GraphQL */ `
  fragment POTransportSummaryLocation on LocationWithMilestones {
    location {
      id
      name
      facility {
        iataCode
      }
    }
    milestoneViews {
      full {
        type
        dateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
        otherDateInfo {
          eventDateTime {
            date
            timestamp
            zone
          }
          timeframeStart {
            date
            timestamp
            zone
          }
          timeframeEnd {
            date
            timestamp
            zone
          }
          lastFetched
          sourceName
          order
        }
        label
        order
      }
    }
  }
`);

export const searchPurchaseOrdersByCustomerIdQuery = graphql(/* GraphQL */ `
  query searchPurchaseOrdersByCustomerId(
    $page: Int! = 0
    $size: Int! = 20
    $customerId: ID!
    $criteria: [SearchCriteria!]
    $sort: [SearchSortCriteria!]
    $macros: [GqlMacro!]
  ) {
    searchPurchaseOrdersByCustomerId(
      page: $page
      size: $size
      customerId: $customerId
      criteria: $criteria
      macros: $macros
      sort: $sort
    ) {
      page
      pageSize
      totalPages
      totalRecords
      pos {
        id
        createdOn
        customerSupplierId
        poNumber
        supplierName
        buyerName
        buyerId
        forwarderName
        cargoReadyDate
        deliveryDate
        orderConfirmedDate
        orderDate
        isWatched
        labels {
          id
          name
        }
        originLocation {
          name
          id
        }
        destinationLocation {
          name
          id
        }
        documents {
          id
          associatedId
          sizeBytes
          type
          name
          createdAt
          createdBy
          createdByUserId
          sizeBytes
          boardId
        }
        skus {
          skuNumber
          description
          quantity
          unitPrice {
            currency
            number
          }
          totalPrice {
            currency
            number
          }
        }
        associatedCargos {
          id
          errors {
            type
          }
          carrierShipmentId
          status
          freshnessTimestamp
          mode
          placeholder
          timeline {
            legs {
              location {
                name
                unLocation {
                  code
                  countryName
                }
              }
              locationLabel
              onwardTransportInfo {
                mode
                vehicle {
                  displayName
                }
                isCurrent
              }
              isFinalDestination
              milestones {
                order
                label
                hasBeenAchieved
                otherDateInfoLabel
                otherDateInfo {
                  lastFetched
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  sourceName
                }
                dateInfo {
                  eventDateTime {
                    date
                    timestamp
                    zone
                  }
                  sourceName
                  lastFetched
                }
              }
            }
          }
          numberOfPackages
          customFields {
            key
            value
          }
          container {
            containerNumber
          }
          demurrageInfo {
            daysOnQuayPoD
            daysAtTsPort
            daysOffQuayDest
          }
          documents {
            id
            associatedId
            sizeBytes
            type
            name
            createdAt
            createdBy
            createdByUserId
            sizeBytes
            boardId
          }
          createdAt
          createdByName
          carrier {
            displayName
            nmftaCode
            iataCode
          }
          milestoneViews {
            po {
              type
              dateInfo {
                eventDateTime {
                  date
                  timestamp
                  zone
                }
                timeframeStart {
                  date
                  timestamp
                  zone
                }
                timeframeEnd {
                  date
                  timestamp
                  zone
                }
                lastFetched
                sourceName
                order
              }
              label
              order
              isStandalonePreviousDateMilestone
              type
            }
          }
          mode
          modeOfTracking
          status
          transportSummary {
            legs
            currentLeg
            haulagePairExists
            currentVehicle {
              identification {
                licensePlate
              }
            }
            prePrimaryLoad {
              ...POTransportSummaryLocation
            }
            origin {
              ...POTransportSummaryLocation
            }
            primaryLoad {
              ...POTransportSummaryLocation
            }
            transshipments {
              location {
                id
                name
                facility {
                  iataCode
                }
                unLocation {
                  code
                  countryName
                }
              }
              milestoneViews {
                summary {
                  type
                  label
                  dateInfo {
                    eventDateTime {
                      date
                      timestamp
                      zone
                    }
                    lastFetched
                  }
                  hasBeenAchieved
                }
              }
            }
            prePrimaryLoad {
              ...TransportSummaryLocation
            }
            origin {
              ...TransportSummaryLocation
            }
            primaryLoad {
              ...TransportSummaryLocation
            }
            transshipments {
              ...TransportSummaryLocation
            }
            primaryDischarge {
              ...TransportSummaryLocation
            }
            postPrimaryDischarge {
              ...TransportSummaryLocation
            }
            destination {
              ...TransportSummaryLocation
            }
            warehouse {
              ...TransportSummaryLocation
            }
          }
          shipmentInfo {
            currentSplit
            totalSplits
          }
          shipmentReferences {
            id
            type
            value
            purchaseOrderId
          }
          slimPurchaseOrders {
            poNumber
            id
            supplierName
          }
          isWatched
        }
        version
      }
    }
  }
`);
