import { graphql } from '../../../../generated/gql';

export const createSupplierMutation = graphql(/* GraphQL */ `
  mutation createSupplier(
    $supplierName: String!
    $relationshipToCustomer: RelationshipToCustomer
    $externalBoardId: ID!
  ) {
    createSupplier(
      supplierName: $supplierName
      relationshipToCustomer: $relationshipToCustomer
      externalBoardId: $externalBoardId
    ) {
      legalName
      supplierId
    }
  }
`);
