import { graphql } from '../../../../generated/gql';

export const addAddressBookEntryMutation = graphql(/* GraphQL */ `
  mutation addAddressBookEntry($addressBookEntry: AddressBookEntryInput!, $externalBoardId: ID!) {
    addAddressBookEntry(addressBookEntry: $addressBookEntry, externalBoardId: $externalBoardId) {
      id
      name
      createdByName
      createdAt
      updatedAt
      updatedByName
      location {
        name
        type
        address {
          address1
          address2
          city
          country
          postcode
        }
        id
        zone
      }
    }
  }
`);
